<template>
    <Head title="" />
    <div class="bg-gray-50 text-black/50 dark:bg-black dark:text-white/50">
        <img
            id="background"
            class="fixed max-w-[400px]"
            src="/images/RC-logo_button-CMYK-cs3_big.svg"
            style="rotate: -35deg;"
        />
        <div
            class="relative min-h-screen flex flex-col items-center justify-center selection:bg-[#FF2D20] selection:text-white"
        >
            <div class="relative w-full max-w-2xl px-6 lg:max-w-7xl">
                <header class="grid grid-cols-1 items-center gap-2 lg:grid-cols-3">
                    <div class="flex lg:justify-center lg:col-start-2 justify-end">
                        <img class="h-40 w-auto hidden md:block" src="/images/YRC-LogoSquare-alpha.png" />
                    </div>
                    <nav class="flex flex-1 justify-end">
                        <Link
                            :href="route('dashboard')"
                            class="rounded-md px-3 py-2 text-black ring-1 ring-transparent transition hover:text-black/70 focus:outline-none focus-visible:ring-[#FF2D20] dark:text-white dark:hover:text-white/80 dark:focus-visible:ring-white"
                        >
                            {{ $page.props.data.translations['Members Area'] }}
                        </Link>
                    </nav>
                </header>

                <main class="mt-6">
                    <div class="grid gap-4 lg:grid-cols-2">
                        <div class="flex flex-col items-center gap-4 overflow-hidden rounded-lg bg-white p4 shadow-[-8px_8px_10px_2px_rgba(0,0,0,0.1)] ring-1 ring-black/[0.05] transition duration-300 hover:text-black/70 hover:ring-black/20 focus:outline-none focus-visible:ring-[#FF2D20] md:row-span-3 lg:p-4 dark:bg-zinc-900 dark:ring-zinc-800 dark:hover:text-white/70 dark:hover:ring-zinc-700 dark:focus-visible:ring-[#FF2D20] relative">
                            <p class="text-4xl color-rc-blue font-bold text-center [text-shadow:_0_1px_2px_rgb(0_0_0_/30%)]">{{ $page.props.data.translations['The Yverdon Repair Cafe'] }}</p>
                            <p class="text-center text-lg">{{ $page.props.data.translations['SubTitle'] }}</p>

                            <div class="p-6">
                                <p class="font-bold text-xl">{{ $page.props.data.translations['Expertise represented at the next event'] }}:</p>
                                <div v-for="(position_translated, position) in availablePositions" :key="position">
                                    <span v-if="nextEventFilledPositions.includes(position)" class="text-green-500">✔️</span>
                                    <span v-else class="text-red-500">❌</span>
                                    {{ position_translated }}
                                </div>
                            </div>

                            <div class="mt-6">
                                <p class="text-lg font-semibold color-rc-blue">
                                    <span class="color-rc-orange">NOUVEAU:</span> Affûtez vos outils ! 🔪✂️
                                </p>
                                <p class="mt-2 color-rc-blue">
                                    Jean-François, bénévole et remouleur, partagera son savoir-faire pour vous aider à affûter vos outils (couteaux, ciseaux, chaînes de tronçonneuse, etc.).
                                </p>
                                <div class="mt-4 color-rc-blue text-sm">
                                    <p class="font-bold color-rc-orange">⚠ Limites par personne pour garantir une expérience optimale :</p>
                                    <ul class="list-inside list-disc">
                                        <li>1 chaîne de tronçonneuse</li>
                                        <li>2 couteaux ou ciseaux</li>
                                    </ul>
                                </div>
                            </div>


                            <p class="cursor-pointer underline" @click="showModal = true">
                                {{ $page.props.data.translations['See the upcoming dates'] }}
                            </p>
                            <Modal
                                :show="showModal"
                                maxWidth="lg"
                                @close="showModal = false"
                            >
                                <template #default>
                                    <div class="p-4">
                                        <img
                                            :src="`/images/Flyer_${$page.props.flyerYear}.jpg`"
                                            alt="Flyer"
                                            class="max-w-full rounded"
                                        />
                                    </div>
                                </template>
                            </Modal>


                            <div class="lg:absolute lg:bottom-0 mx-4 mb-4 text-sm">
                                <p>
                                    <span>Contact: </span>
                                    <a :href="`mailto:`+ contactMail">
                                        <font-awesome-icon :icon="['fas', 'envelope']" class="mx-2"/> {{ contactMail }}
                                    </a>
                                </p>
                                <p v-html="$page.props.data.translations['FinancialHelpSentence']"></p>
                            </div>
                        </div>

                        <div class="flex items-start gap-4 rounded-lg bg-white p4 shadow-[-8px_8px_10px_2px_rgba(0,0,0,0.1)] ring-1 ring-black/[0.05] transition duration-300 hover:text-black/70 hover:ring-black/20 focus:outline-none focus-visible:ring-[#FF2D20] dark:bg-zinc-900 dark:ring-zinc-800 dark:hover:text-white/70 dark:hover:ring-zinc-700 dark:focus-visible:ring-[#FF2D20]">
                            <vue-countdown :time="nextEventDateTimestamp" v-slot="{ days, hours, minutes, seconds }" style="width: 100%">
                                <div class="countdown-message">{{ $page.props.data.translations['NextEventIn'] }}</div>
                                <div class="countdown-container">
                                    <div class="countdown-wrapper">
                                        <div class="countdown-item">
                                            <span class="countdown-number">{{ String(days).padStart(2, '0') }}</span>
                                            <span class="countdown-label">{{ $page.props.data.translations['days'] }}</span>
                                        </div>
                                        <div class="countdown-item">
                                            <span class="countdown-number">{{ String(hours).padStart(2, '0') }}</span>
                                            <span class="countdown-label">{{ $page.props.data.translations['hours'] }}</span>
                                        </div>
                                        <div class="countdown-item">
                                            <span class="countdown-number">{{ String(minutes).padStart(2, '0') }}</span>
                                            <span class="countdown-label">{{ $page.props.data.translations['minutes'] }}</span>
                                        </div>
                                        <div class="countdown-item">
                                            <span class="countdown-number">{{ String(seconds).padStart(2, '0') }}</span>
                                            <span class="countdown-label">{{ $page.props.data.translations['seconds'] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </vue-countdown>
                        </div>

                        <div class="flex flex-col items-center justify-center rounded-lg bg-white p4 shadow-[-8px_8px_10px_2px_rgba(0,0,0,0.1)] ring-1 ring-black/[0.05] transition duration-300 hover:text-black/70 hover:ring-black/20 focus:outline-none focus-visible:ring-[#FF2D20] dark:bg-zinc-900 dark:ring-zinc-800 dark:hover:text-white/70 dark:hover:ring-zinc-700 dark:focus-visible:ring-[#FF2D20]">

                            <div>
                                <font-awesome-icon icon="fa-solid fa-location-dot" size="3x" border color="#fe4212"/>
                            </div>

                            <div class="font-bold text-xl uppercase">{{ $page.props.data.translations['Where?'] }}</div>

                            <div class="px-4 py-2 my-2 w-full text-center" style="background-color: #1c1283">
                                <span class="text-white text-xl">{{ nextEvent?.address_title ?? $page.props.data.translations['No next event'] }}</span>
                            </div>

                            <div class="px-4 py-2 my-2 w-full text-center" style="color: #1c1283">
                                <p class="text-xl">{{ nextEvent?.address_details }}</p>
                                <p class="text-xl">{{ nextEvent?.address_city }}</p>
                            </div>

                        </div>

                        <div class="flex flex-col items-center justify-center rounded-lg bg-white p4 shadow-[-8px_8px_10px_2px_rgba(0,0,0,0.1)] ring-1 ring-black/[0.05] transition duration-300 hover:text-black/70 hover:ring-black/20 focus:outline-none focus-visible:ring-[#FF2D20] dark:bg-zinc-900 dark:ring-zinc-800 dark:hover:text-white/70 dark:hover:ring-zinc-700 dark:focus-visible:ring-[#FF2D20]">

                            <div>
                                <font-awesome-icon :icon="['fas', 'clock']" size="3x" border color="#1c1283"/>
                            </div>

                            <div class="font-bold text-xl uppercase">{{ $page.props.data.translations['When?'] }}</div>

                            <div class="px-4 py-2 my-2 w-full text-center" style="background-color: #fe4212">
                                <span class="text-white text-xl capitalize">{{ nextEventDateText }}</span>
                            </div>

                            <div class="px-4 py-2 my-2 w-full text-center" style="color: #fe4212">
                                <p class="text-xl">{{ nextEventTimeText}}</p>
                            </div>

                        </div>
                    </div>
                </main>
                <footer class="py-5 text-center text-sm text-black dark:text-white/70">
                    <div class="flex items-center justify-center">
                        <img class="h-12 w-auto ml-2" src="/images/logo-integration-RC-400x75.png" alt="Logo"/>
                    </div>
                </footer>

            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {Head, Link} from '@inertiajs/vue3';
import VueCountdown from '@chenfengyuan/vue-countdown';
import Modal from '@/Components/Modal.vue';
import { Event } from "@/types"
import { ref } from 'vue';

const props = defineProps<{
    appName?: string;
    contactMail?: string;
    nextEventDateTimestamp: number;
    nextEventDateText?: string;
    nextEventTimeText?: string;
    nextEvent?: Event;
    flyerYear: string;
    nextEventFilledPositions: Record<number, string>;
    availablePositions: Record<string, string>;
}>();

const showModal = ref(false)
const nextEventFilledPositions = Object.values(props.nextEventFilledPositions)



</script>
